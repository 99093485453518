<template>
  <b-modal
    id="review-message"
    title="Review Message"
    centered
    size="lg"
    ok-title="Send"
    ok-variant="primary"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="sendMessage"
  >
    <!-- hide-footer -->

    <b-form-group label="Message" class="mb-0">
      <b-form-textarea
        v-model="message"
        input-id="message"
        id="message"
        placeholder="Message"
        rows="6"
        readonly
      />
      <small
        >{{ messageCharacters }} characters.
        {{ noOfMessages }} message(s)</small
      >
    </b-form-group>
    <!-- <div class="text-right">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="$bvModal.hide('review-message')"
        class="mr-2"
      >
        Cancel
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="sendMessage"
      >
        <span class="align-middle">Send</span>
        <feather-icon icon="SendIcon" class="ml-50" />
      </b-button>
    </div> -->
  </b-modal>
</template>

<script>
import { BFormGroup, BFormTextarea } from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: ["message", "messageCharacters", "noOfMessages"],
  emits: ["send-message"],
  methods: {
    sendMessage() {
      this.$emit("send-message");
    },
  },
};
</script>