<template>
  <b-card no-body>
    <b-row class="m-2">
      <b-col>
        <h2>Compose and Schedule SMS Messages</h2>
        <p class="my-1">Compose a message and schedule the time you want it to be sent.</p>
        <hr />
        <!-- Body -->
       
        <compose-message-form></compose-message-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ComposeMessageForm from "./components/ComposeMessageForm.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    ComposeMessageForm,
  }
};
</script>
